  <template>
  <div>
    <v-row class="ma-2 flex-column">
      <v-radio-group
        v-model="frequency"
        :disabled="!promise.canUpdate"
        row
      >
        <v-radio
          :label="$t('t.Specific')"
          value="SpecificDates"
        ></v-radio>
        <v-radio
          :label="$t('t.Monthly')"
          value="Monthly"
        ></v-radio>
        <v-radio
          :label="$t('t.Weekly')"
          value="Weekly"
        ></v-radio>
      </v-radio-group>
      <div class="d-flex flex-wrap align-baseline">
        <div
          class="d-flex flex-row align-center"
          v-if="!promise.status || !promise.status.id"
        >
          <v-btn
            class="mx-1"
            @click="numberOfInstallments--"
            fab
            icon
            small
            :disabled="!promise.canUpdate || (numberOfInstallments <= minInstallments || this.isInstallmentPaid(this.promiseInstallments[this.numberOfInstallments -1 ]))"
          >
            <v-icon>{{$icon('i.Minus')}}</v-icon>

          </v-btn>
          <v-text-field
            class="number-installment"
            type="number"
            hide-details
            :disabled="isInstallmentPaid(promiseInstallments[0])"
            v-model="numberOfInstallments"
            :rules="[v => v >= minInstallments && v <= maxInstallments]"
            outlined
          >
          </v-text-field>
          <v-btn
            class="mx-1"
            @click="numberOfInstallments++"
            fab
            icon
            small
            :disabled="!promise.canUpdate || numberOfInstallments >= maxInstallments"
          >
            <v-icon>{{$icon('i.Plus')}}</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <v-text-field
          :label="`${$t('t.Total')} :`"
          outlined
          :value="formatCurrency(totalAmountFromInstallments, promise.currency) + ' / ' + formatCurrency(promise.amount || 0, promise.currency)"
          readonly
          :rules="[() => !!promise.isTotalAmountFromInstallmentsValide]"
        />
      </div>
    </v-row>
    <div
      v-for="(installment, index) in promiseInstallments"
      :key="installment.id"
    >
      <v-row
        class="ml-3 mr-3"
        dense
      >
        <v-col cols="5">
          <date-picker-menu
            v-model="installment.targetDate"
            @input="updateInstallmentDates()"
            :label="$t('t.TargetDate')"
            :disabled="!canModifyInstallment(installment) || (!isInstallmentDateEditable && index !== 0)"
            :rules="installmentDateRules"
            :clearable="canModifyInstallment(installment) && (isInstallmentDateEditable || index === 0)"
            :min="getMinDate(index)"
            :max="getMaxDate(index)"
          />
        </v-col>
        <v-col cols="5">
          <amount-field
            type="Number"
            :currency="promise.currency"
            :disabled="!canModifyInstallment(installment)"
            :rules="installmentAmountRules"
            :amount.sync="installment.amount"
            @update:amount="updateInstallments()"
            :label="$t('t.Amount')"
            class="ml-5"
          >
          </amount-field>
        </v-col>
        <v-col
          cols="1"
          class="d-flex justify-center align-center"
        >
          <v-btn
            icon
            color="red"
            @click="removeInstallment(index)"
            v-if="index !== 0 && canModifyInstallment(installment) && canModifyInstallment(promiseInstallments[index-1])"
          >
            <v-icon>{{$icon('i.Close')}}</v-icon>
          </v-btn>
          <icon-tooltip
            v-if="installment.paidDate"
            right
            color="success"
            :disabled="!isInstallmentPaid(installment)"
            :icon-name="'i.Treated'"
            :tooltip-text="tooltipPaidInstallment(installment)"
          />
          <v-icon
            v-if="promiseIsBroken && !installment.paidDate"
            color="error"
          >{{$icon("i.AlertCircleOutline")}}</v-icon>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    AmountField: () => import('@/components/amount-field'),
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      maxInstallments: 40,
      minInstallments: 1,
      required: v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')
    }
  },
  computed: {
    frequency: {
      get () {
        return this.promise.frequency === 'OneTime' ? 'SpecificDates' : this.promise.frequency
      },
      set (frequency) {
        this.$emit('update:promise', Object.assign({}, this.promise, { frequency: (frequency === 'SpecificDates' && this.promise.installments.length === 1) ? 'OneTime' : frequency }))
        this.$nextTick(this.updateInstallmentDates)
      }
    },
    installmentAmountRules () {
      return [
        this.required,
        v => v > 0 || this.$t('t.MustBeGreaterThan', { value: 0 }),
        v => this.promise.isToleranceAmountPercentage || v > this.promise.toleranceAmount || this.$t('t.InvalidTolerance')
      ]
    },
    installmentDateRules () {
      return [
        this.required,
        v => this.moment(v).format('YYYY-MM-DD') !== this.invalidInstallmentDateWithDaysTolerance
      ]
    },
    invalidInstallmentDateWithDaysTolerance () {
      let days
      let date
      this.promise.installments.forEach((i, index) => {
        if (index) {
          const daysAfterPreviousInstallment = this.moment(i.targetDate).diff(this.moment(this.promise.installments[index - 1].targetDate, 'YYYY-MM-DD'), 'days')
          if (daysAfterPreviousInstallment < days) {
            days = daysAfterPreviousInstallment
            if (days <= this.promise.toleranceDays) {
              date = i.targetDate
            }
          }
        } else {
          days = 100
        }
      })
      return date
    },
    numberOfInstallments: {
      get () {
        return this.promiseInstallments.length
      },
      set (numberOfInstallments) {
        if (numberOfInstallments >= this.minInstallments && numberOfInstallments <= this.maxInstallments) {
          const installmentChange = numberOfInstallments - this.promiseInstallments.length
          if (installmentChange > 0) {
            this.addInstallment(installmentChange)
          }
          if (installmentChange < 0) {
            this.removeInstallment(undefined, -installmentChange)
          }
        }
      }
    },
    promiseInstallments: {
      get () {
        return this.promise.installments
      },
      set (installments) {
        this.updateInstallments(installments)
        this.numberOfInstallments = this.promiseInstallments.length
        if (['Weekly', 'Monthly'].includes(this.frequency)) {
          this.updateInstallmentDates()
        }
      }
    },
    promiseIsBroken () {
      return this.promise?.status?.id === 2
    },
    isInstallmentDateEditable () {
      return !['Weekly', 'Monthly'].includes(this.frequency)
    },
    totalAmountFromInstallments () {
      return this.promiseInstallments.reduce((acc, installment) => acc + installment.amount, 0)
    }
  },
  methods: {
    addInstallment (count = 1) {
      for (let i = 0; i < count; i++) {
        const installment = {
          id: this.uuid(),
          targetDate: null,
          amount: null
        }
        this.promiseInstallments.push(installment)
      }
      this.updateInstallmentDates()
    },
    canModifyInstallment (installement) {
      return (this.promise?.status?.id ?? 0) === 0 && !this.isInstallmentPaid(installement)
    },
    getFirstDate () {
      let firstDate = this.moment()
      if (this.promiseInstallments[0].targetDate != null) {
        firstDate = this.moment(this.promiseInstallments[0].targetDate, 'YYYY-MM-DD')
      }
      return firstDate
    },
    isInstallmentPaid (installment) {
      // return this.moment().diff(this.moment(installment.targetDate, 'YYYY-MM-DD'), 'days') > 0
      return !!installment.paidDate
    },
    removeInstallment (index, count = 1) {
      const indexIsDefined = typeof index !== 'undefined'
      if (count === 1) {
        const installmentIndex = indexIsDefined ? index : this.promiseInstallments.length - 1
        this.promiseInstallments.splice(installmentIndex, 1)
      } else {
        for (let i = 0; i < count; i++) {
          this.promiseInstallments.splice(this.promiseInstallments.length - 1, 1)
        }
      }
      if (indexIsDefined) {
        this.numberOfInstallments = this.promiseInstallments.length
      }
      this.updateInstallmentDates()
    },
    setMonthlyInstallments () {
      const isEndOfMonth = this.getFirstDate().endOf('month').date() === this.getFirstDate().date()
      this.promiseInstallments.forEach((p, i) => {
        p.targetDate = isEndOfMonth ? this.getFirstDate().add(i, 'months').endOf('month').format('YYYY-MM-DD') : this.getFirstDate().add(i, 'months').format('YYYY-MM-DD')
      })
    },
    setWeeklyInstallments () {
      this.promiseInstallments.forEach((p, i) => {
        p.targetDate = this.getFirstDate().add(i, 'weeks').format('YYYY-MM-DD')
      })
    },
    splitAmountOnInstallments () {
      if (
        this.promiseInstallments.length > 0 &&
        this.promiseInstallments.every(pi => !this.isInstallmentPaid(pi) && (!pi.amount || pi.amount === this.promiseInstallments[0].amount))
      ) {
        const splitedAmount = this.lodash.round(this.promise.amount / this.promiseInstallments.length, 2)
        this.promiseInstallments.forEach(function (installment) {
          installment.amount = splitedAmount
        })
      }
      this.updateInstallments()
    },
    updateInstallments (promiseInstallments = this.promiseInstallments) {
      this.$emit('update:promise', Object.assign({}, this.promise, { promiseInstallments, isTotalAmountFromInstallmentsValide: this.promise.amount === this.lodash.round(promiseInstallments.reduce((acc, installment) => acc + installment.amount, 0), 2) }))
    },
    updateInstallmentDates () {
      switch (this.frequency) {
        case 'Weekly': this.setWeeklyInstallments()
          break
        case 'Monthly': this.setMonthlyInstallments()
          break
        default:
          break
      }

      this.splitAmountOnInstallments()
    },
    getMinDate (currentIndex) {
      const previousInstallment = {}
      let i = currentIndex
      while (--i >= 0 && !previousInstallment.targetDate) {
        previousInstallment.index = i
        previousInstallment.targetDate = this.promiseInstallments[i].targetDate
      }

      return previousInstallment.targetDate && !this.isInstallmentPaid(previousInstallment)
        ? this.moment(previousInstallment.targetDate, 'YYYY-MM-DD').add(currentIndex - previousInstallment.index, 'd').format('YYYY-MM-DD')
        : this.moment().add(currentIndex - (this.isInstallmentPaid(previousInstallment) ? (previousInstallment.index + 1) : 0), 'd').format('YYYY-MM-DD')
    },
    getMaxDate (currentIndex) {
      if (this.frequency !== 'OneTime') return null
      const nextInstallment = {}
      let i = currentIndex
      while (++i < this.promiseInstallments.length && !nextInstallment.targetDate) {
        nextInstallment.index = i
        nextInstallment.targetDate = this.promiseInstallments[i].targetDate
      }

      return !nextInstallment.targetDate
        ? null
        : this.moment(nextInstallment.targetDate, 'YYYY-MM-DD')
          .add(currentIndex - nextInstallment.index, 'd')
          .format('YYYY-MM-DD')
    },
    tooltipPaidInstallment (installment) {
      return this.$t('t.InstallmentPaid') + ' ' + this.$t('t.At').toLowerCase() + ' ' + this.formatDate(installment.paidDate)
    }
  },
  props: {
    promise: Object
  }
}
</script>

<style lang="stylus" scoped>
.document-list
  height 400px

.number-installment
  max-width 80px
</style>
